/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLaptopArrowDown01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgLaptopArrowDown01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 17v-6.6c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C15.361 8 14.941 8 14.1 8H6.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581V17M18 7l2 2m0 0 2-2m-2 2V3M5 20h11c.465 0 .698 0 .888-.051a1.5 1.5 0 0 0 1.06-1.06C18 18.696 18 18.464 18 18c0-.233 0-.349-.026-.444a.75.75 0 0 0-.53-.53C17.35 17 17.233 17 17 17H4c-.232 0-.349 0-.444.026a.75.75 0 0 0-.53.53C3 17.65 3 17.767 3 18c0 .465 0 .697.051.888a1.5 1.5 0 0 0 1.06 1.06C4.304 20 4.536 20 5 20"
    />
  </svg>
)
