/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLightbulbArrowDown01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgLightbulbArrowDown01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11 4v.8M3.8 12H3m2.8-5.2-.48-.48M19 12h-.8m-8.8 1.2h3.2m-1.6 0v4M18 7l2 2m0 0 2-2m-2 2V3m-6.2 12.9a4.8 4.8 0 1 0-5.6 0v1.54c0 .896 0 1.344.174 1.686a1.6 1.6 0 0 0 .7.7c.342.174.79.174 1.686.174h.48c.896 0 1.344 0 1.686-.174a1.6 1.6 0 0 0 .7-.7c.174-.342.174-.79.174-1.686z"
    />
  </svg>
)
