/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCoinsPlus01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCoinsPlus01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 5c0 1.105-2.462 2-5.5 2S4 6.105 4 5m11 0c0-1.105-2.462-2-5.5-2S4 3.895 4 5m11 0v9c-1.5.5-4.17 1-5.5 1-3.038 0-5.5-.895-5.5-2m0-8v12c0 1.105 2.462 2 5.5 2 1.33 0 2.55-.172 3.5-.457M4 9c0 1.105 2.462 2 5.5 2 1.33 0 4.5-.5 5.5-1m3 10v-3m0 0v-3m0 3h-3m3 0h3"
    />
  </svg>
)
