/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMicroscopeArrowDown01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMicroscopeArrowDown01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 21h7.5M9.667 7.614a6.6 6.6 0 0 1 1.666-.214C15.015 7.4 18 10.444 18 14.2c0 2.854-1.723 5.297-4.167 6.306M18 7l2 2m0 0 2-2m-2 2V3M5.083 13.35h3.334c.387 0 .58 0 .741.033a1.68 1.68 0 0 1 1.31 1.335c.032.165.032.362.032.757s0 .592-.032.757a1.68 1.68 0 0 1-1.31 1.335c-.16.033-.354.033-.741.033H5.083c-.387 0-.58 0-.741-.033a1.68 1.68 0 0 1-1.31-1.335C3 16.067 3 15.87 3 15.475s0-.592.032-.757a1.68 1.68 0 0 1 1.31-1.335c.16-.033.354-.033.741-.033m-1.25-6.375v6.375h5.834V6.975C9.667 5.332 8.36 4 6.75 4S3.833 5.332 3.833 6.975"
    />
  </svg>
)
