/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCouch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCouch"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.5 10H5v4h14v-4h1.5m-17 0H2v6a2 2 0 0 0 2 2h2m-2.5-8V7a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v3m0 0H22v6a2 2 0 0 1-2 2h-2m0 0 1 4m-1-4H6m0 0-1 4"
    />
  </svg>
)
