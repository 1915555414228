/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCalendarShield01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCalendarShield01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21 8H3m13-6v3M8 2v3m-.2 17h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 19.72 21 18.88 21 17.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 22 6.12 22 7.8 22m7.7-6.975c0 2.232-2.342 3.855-3.195 4.372-.096.058-.145.088-.213.103a.5.5 0 0 1-.184 0c-.068-.015-.116-.045-.213-.103-.853-.517-3.195-2.14-3.195-4.372V12.85c0-.363 0-.545.057-.701a.9.9 0 0 1 .24-.359c.12-.11.284-.174.611-.302l2.346-.914c.091-.036.137-.053.183-.06a.4.4 0 0 1 .126 0c.046.007.092.024.183.06l2.346.914c.327.128.49.191.611.302a.9.9 0 0 1 .24.359c.057.156.057.338.057.701z"
    />
  </svg>
)
