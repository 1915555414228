/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBarChartSlash01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBarChartSlash01"
    {...props}
  >
    {props.children}
    <path
      stroke="#32497f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 7H4.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 7.76 3 8.04 3 8.6v8.5M9 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.181-.119M9 21h6m-6 0v-5.3m6-4.7h4.4c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437c.11.214.11.494.11 1.054v6.8c0 .56 0 .84-.11 1.054a1 1 0 0 1-.437.437C20.24 21 19.96 21 19.4 21H15m0 0V10.3M2 22l1.365-1.228M22 4l-7 6.3m0 0-6 5.4m0 0-5.635 5.072M9 11.7V4.6c0-.56 0-.84.11-1.054a1 1 0 0 1 .436-.437C9.76 3 10.04 3 10.6 3h2.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437c.11.214.11.494.11 1.054v1.7"
    />
  </svg>
)
