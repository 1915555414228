/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgConcentricHexagons = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 25"
    data-name="SvgConcentricHexagons"
    {...props}
  >
    {props.children}
    <path
      stroke="#32497F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.23 3.067c.284-.158.426-.237.576-.268a1 1 0 0 1 .403 0c.15.031.292.11.576.268l7.4 4.11c.3.167.45.25.558.369.097.104.17.228.214.364.05.152.05.324.05.666v8.117c0 .343 0 .514-.05.667a1 1 0 0 1-.214.364c-.109.119-.259.202-.558.368l-7.4 4.111c-.284.158-.426.236-.576.267q-.202.042-.403 0c-.15-.03-.292-.11-.575-.267l-7.4-4.11c-.3-.167-.45-.25-.559-.369a1 1 0 0 1-.214-.364c-.05-.153-.05-.324-.05-.667V8.576c0-.342 0-.514.05-.666a1 1 0 0 1 .214-.364c.11-.119.26-.202.559-.368z"
    />
    <path
      fill="#32497F"
      d="M11.689 8.71c.116-.064.174-.096.236-.109a.4.4 0 0 1 .165 0c.062.013.12.045.236.11l3.035 1.686c.123.068.184.102.23.15a.4.4 0 0 1 .087.15c.02.063.02.133.02.274V14.3c0 .14 0 .21-.02.273a.4.4 0 0 1-.088.15c-.045.048-.106.082-.229.15l-3.035 1.686a1 1 0 0 1-.236.11.4.4 0 0 1-.165 0c-.062-.013-.12-.045-.236-.11l-3.035-1.686c-.123-.068-.184-.102-.23-.15a.4.4 0 0 1-.087-.15c-.02-.063-.02-.133-.02-.274v-3.328c0-.141 0-.211.02-.274a.4.4 0 0 1 .088-.15c.045-.048.106-.082.229-.15z"
    />
  </svg>
)
