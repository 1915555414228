/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCouchArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCouchArrowDown"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.125 10.824H5.25v3.058h10.5v-3.059h1.125m-12.75 0H3v4.118a2 2 0 0 0 2 2h1m-1.875-6.118V9a2 2 0 0 1 2-2h8.75a2 2 0 0 1 2 2v1.824m0 0H18v4.117a2 2 0 0 1-2 2h-1m0 0L15.75 20M15 16.941H6m0 0L5.25 20M18 6l2 2m0 0 2-2m-2 2V2"
    />
  </svg>
)
