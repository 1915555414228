/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLockDollar01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgLockDollar01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 9V7c0-2.761-2.239-4-5-4S7 4.239 7 7v2m5 3.09h.944c.599 0 1.118.296 1.378.728M12 12.091h-.833c-.92 0-1.667.651-1.667 1.455 0 .803.746 1.454 1.667 1.454h1.666c.92 0 1.667.651 1.667 1.454 0 .804-.746 1.455-1.667 1.455H12m0-5.818V11m0 6.91h-.944c-.599 0-1.118-.296-1.379-.728m2.323.727V19m-3.2 2h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2v-1.4c0-1.68 0-3.52-.327-4.162a3 3 0 0 0-1.311-1.311C17.72 9 16.88 9 15.2 9H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 11.28 4 13.12 4 14.8v1.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 21 7.12 21 8.8 21"
    />
  </svg>
)
