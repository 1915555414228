/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBuilding02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 20 20"
    data-name="SvgBuilding02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M4.161 0H15.84c.527 0 .981 0 1.356.03.395.033.789.104 1.167.297a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356V5c0 .926-.997 2-2.667 2s-2.666-1.074-2.666-2a1 1 0 1 0-2 0c0 .926-.997 2-2.667 2S7.333 5.926 7.333 5a1 1 0 1 0-2 0c0 .926-.997 2-2.666 2S0 5.926 0 5v-.839c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167A3 3 0 0 1 1.638.327C2.016.134 2.41.063 2.805.031 3.18 0 3.635 0 4.161 0"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M17.333 9A5.3 5.3 0 0 0 20 8.282v7.557c0 .527 0 .981-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.375.031-.83.031-1.356.031H4.16c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167A18 18 0 0 1 0 15.839V8.282A5.3 5.3 0 0 0 2.667 9c1.405 0 2.778-.562 3.666-1.514C7.222 8.438 8.595 9 10 9s2.778-.562 3.667-1.514C14.555 8.438 15.927 9 17.333 9M8.6 12h2.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C13 12.76 13 13.04 13 13.6V18H7v-4.4c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C7.76 12 8.04 12 8.6 12"
      clipRule="evenodd"
    />
  </svg>
)
