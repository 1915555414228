/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHandShakeDollar01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgHandShakeDollar01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.396 14.15v-.984a.33.33 0 0 1 .33-.33h2.972a.33.33 0 0 1 .33.33v6.9a.33.33 0 0 1-.33.33h-2.972a.33.33 0 0 1-.33-.33v-.494m0-5.422c-2.625-1.082-3.468-2.724-5.549-1.798-.763.34-1.106.744-1.681 1.322m7.23.476v5.422m-3.104.494c.336-1.876.14-3.165-2.053-4.933-.321.06-1.797 1.026-1.797 1.026s-1.925.424-1.725-.92a45 45 0 0 1 1.449-1.565m4.126 6.392c1.235-.058 1.915-.151 3.104-.494m-3.104.494c-.032 1.3-3.577 1.36-3.577 1.36s-2.069.166-5.086-1.36l-.931-.144m5.468-6.248s-3.35.272-5.468.67m0 0v-1.178a.33.33 0 0 0-.33-.33H2.396a.33.33 0 0 0-.33.33v6.9c0 .182.148.33.33.33h2.972a.33.33 0 0 0 .33-.33v-.144m0-5.578v5.578M13.715 3h-2m0 0h-.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 1 1 0 3h-.5m0-6V2m0 7h-2m2 0v1"
    />
  </svg>
)
