/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAi"
    {...props}
  >
    {props.children}
    <g fill="#32497f">
      <path d="m3.636 7 .286.57c.096.194.144.29.209.374q.086.111.198.198c.084.064.18.113.373.21l.57.284-.57.286c-.193.096-.29.144-.373.209q-.111.086-.198.198c-.065.084-.113.18-.21.373l-.285.57-.285-.57c-.096-.193-.145-.29-.21-.373a1 1 0 0 0-.197-.198c-.084-.065-.18-.113-.373-.21L2 8.637l.57-.285c.194-.096.29-.145.374-.21q.111-.085.198-.197c.064-.084.113-.18.21-.373zM6.727 3l.429 1.114c.102.267.154.4.233.512q.107.151.257.257c.112.08.246.131.512.234l1.115.428-1.115.43c-.266.102-.4.153-.512.233q-.15.106-.257.257c-.08.112-.13.245-.233.512L6.727 8.09 6.3 6.977c-.103-.267-.154-.4-.234-.512a1.1 1.1 0 0 0-.257-.257c-.112-.08-.245-.131-.512-.234l-1.114-.429 1.114-.428c.267-.103.4-.154.512-.234q.15-.106.257-.257c.08-.112.131-.245.234-.512zM16.364 17.91l.285.57c.097.193.145.29.21.373q.085.111.197.198c.084.064.18.113.373.21l.571.284-.57.286a2.3 2.3 0 0 0-.374.21q-.111.085-.198.197c-.064.084-.113.18-.209.373l-.285.57-.286-.57c-.096-.193-.144-.29-.209-.373a1 1 0 0 0-.198-.198 2.3 2.3 0 0 0-.373-.21l-.57-.285.57-.285c.193-.096.29-.145.373-.209q.111-.086.198-.198a2.3 2.3 0 0 0 .21-.373zM19.455 13.91l.428 1.114c.103.266.154.4.234.512q.106.15.256.256c.113.08.246.131.513.234l1.114.429-1.114.428c-.267.103-.4.154-.513.234q-.15.106-.256.257c-.08.112-.131.245-.234.512L19.454 19l-.428-1.114c-.103-.267-.154-.4-.234-.512q-.106-.151-.257-.257c-.112-.08-.245-.131-.512-.234l-1.114-.428 1.114-.429c.267-.103.4-.154.513-.234q.15-.106.256-.256c.08-.113.131-.246.234-.513zM8.711 7.398h1.107l3.857 9.011h-1.426l-.903-2.227h-4.29l-.89 2.227H4.74zm2.19 5.715L9.22 8.976h-.026l-1.706 4.137zm3.946-5.715h1.222v9.011h-1.222z" />
    </g>
  </svg>
)
