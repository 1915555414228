/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCalendarKey01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCalendarKey01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21 8H3m13-6v3M8 2v3m6.884 9.382h.006M7.8 22h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 19.72 21 18.88 21 17.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 22 6.12 22 7.8 22m4.926-4.563a3.132 3.132 0 1 0-1.347-3.895c-.087.212-.13.318-.174.375a.45.45 0 0 1-.143.126c-.062.038-.147.061-.317.107l-3.64.99c-.124.034-.185.05-.24.08a.5.5 0 0 0-.128.1c-.043.045-.075.1-.138.211l-.45.787c-.146.254-.218.381-.224.506a.52.52 0 0 0 .084.312c.069.104.196.177.45.322l1.087.622.518-.906.907.519.518-.906.906.519 1.125-.307c.17-.046.255-.069.327-.068.076 0 .118.009.188.037.067.026.158.096.34.235q.165.127.351.234"
    />
  </svg>
)
