/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHeartHand02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgHeartHand02"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 3.986h2.61q.512 0 1.009-.121l2.758-.67a4.3 4.3 0 0 1 1.826-.043l3.05.593a4.2 4.2 0 0 1 2.127 1.108l2.158 2.099c.616.598.616 1.569 0 2.168a1.61 1.61 0 0 1-2.06.143l-2.515-1.835a2.1 2.1 0 0 0-1.25-.405h-2.428 1.546c.871 0 1.577.687 1.577 1.534v.307c0 .704-.492 1.317-1.194 1.487l-2.385.58a5 5 0 0 1-1.186.142c-.965 0-2.711-.799-2.711-.799L6 9.048m-4 .425v-5.8c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437c.214-.109.494-.109 1.054-.109h.8c.56 0 .84 0 1.054.11a1 1 0 0 1 .437.436C6 2.833 6 3.113 6 3.673v5.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437c-.214.11-.494.11-1.054.11h-.8c-.56 0-.84 0-1.054-.11a1 1 0 0 1-.437-.437C2 10.313 2 10.033 2 9.473m14.419 5.97c1.062-.887 2.588-.917 3.54.22s.84 2.751-.221 3.728c-.657.603-2.222 1.642-3.322 2.348-.407.26-.61.39-.833.42-.191.024-.4-.011-.573-.097-.202-.1-.35-.29-.65-.67-.808-1.026-1.947-2.52-2.37-3.306a2.66 2.66 0 0 1 1.012-3.594c1.29-.75 2.704-.235 3.417.952"
    />
  </svg>
)
