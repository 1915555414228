/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgClockDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgClockDollar"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21.95 13q.05-.493.05-1c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10a10 10 0 0 0 1-.05M12 6v6l3.738 1.87m3.762 1.72h.944c.599 0 1.118.296 1.379.728m-2.323-.727h-.833c-.92 0-1.667.651-1.667 1.455 0 .803.746 1.454 1.667 1.454h1.666c.92 0 1.667.651 1.667 1.455 0 .803-.746 1.454-1.667 1.454H19.5m0-5.818V14.5m0 6.91h-.944c-.599 0-1.118-.296-1.379-.728m2.323.727V22.5"
    />
  </svg>
)
