/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCoinsHeart01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCoinsHeart01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14 6.25c0 1.243-2.686 2.25-6 2.25S2 7.493 2 6.25m12 0C14 5.007 11.314 4 8 4S2 5.007 2 6.25m12 0V8.5M2 6.25v10.5C2 17.993 4.686 19 8 19c.701 0 1.374-.045 2-.128M2 9.75C2 10.993 4.686 12 8 12c.701 0 1.374-.045 2-.128m-8 1.376c0 1.242 2.686 2.25 6 2.25.701 0 1.374-.046 2-.128m7.497-2.42c-.9-1.04-2.4-1.319-3.527-.368s-1.286 2.542-.401 3.667S17.497 20 17.497 20s3.043-2.626 3.928-3.75a2.606 2.606 0 0 0-.4-3.668c-1.147-.941-2.628-.672-3.528.367"
    />
  </svg>
)
