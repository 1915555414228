/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankNoteScissors01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBankNoteScissors01"
    {...props}
  >
    {props.children}
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M2 14.5V8.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 5 4.08 5 5.2 5h13.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C22 6.52 22 7.08 22 8.2v7.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C20.48 19 19.92 19 18.8 19h-8.3M2.917 18.972l6.798-2.117m-2.684-2.754-2.289 6.743m-2.916-3.817a1.154 1.154 0 1 1 1.611 1.652 1.154 1.154 0 0 1-1.61-1.652m3.222 3.305a1.154 1.154 0 1 1 1.61 1.652 1.154 1.154 0 0 1-1.61-1.652" />
      <path d="M13.5 9.75h-1.875a1.125 1.125 0 0 0 0 2.25h.75a1.125 1.125 0 0 1 0 2.25H10.5M12 9v.75m0 4.5V15" />
    </g>
  </svg>
)
