/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBuildingStar01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBuildingStar01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13 11h2.4M21 21v-6.8c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.282-.415M13 21V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C11.48 3 10.92 3 9.8 3H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 4.52 3 5.08 3 6.2V21m19 0H2M6.5 7h3m-3 4h3m-3 4h3m9-11-.674 1.753c-.11.286-.165.428-.25.548q-.115.16-.275.275c-.12.085-.262.14-.548.25L15 7.5l1.753.674c.286.11.428.165.548.25q.16.115.275.275c.085.12.14.262.25.548L18.5 11l.674-1.753c.11-.286.165-.428.25-.548q.115-.16.275-.275c.12-.085.262-.14.548-.25L22 7.5l-1.753-.674c-.286-.11-.428-.165-.548-.25a1.2 1.2 0 0 1-.275-.275c-.085-.12-.14-.262-.25-.548z"
    />
  </svg>
)
