/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPalmTree01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgPalmTree01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21 20s-1.19-.47-2-.698c-2.56-.722-4.78-.012-7 .698m-9 0s1.19.47 2 .698q.38.107.751.174M12.25 6c-3.021-.767-5.908-.278-9.75.5 5.564-6.257 8.017-4.529 10-.5 3.54-4.21 7.022-5.089 9.5.5-3.418-.917-6.334-1.302-9.75-.5m0 0v3.224m0-3.224c7.445 1.767 8.606 3.59 6.25 9-.99-3.032-1.987-4.535-4.494-5.35M12.25 6C5.722 8.016 3.197 10.009 6 15c1.07-2.83 2.13-4.519 4.5-5.35m1.75-.426a10 10 0 0 0-1.75.425m1.75-.425c.658.118 1.24.257 1.756.425m-3.506 0c.135 4.92-1.044 7.338-4.749 11.223m0 0C8 21.279 10 20.64 12 20m0 0c1.52-3.97 2.103-6.219 2.006-10.35"
    />
  </svg>
)
