/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGlobeArrowDown01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgGlobeArrowDown01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11 6a12.24 12.24 0 0 1 3.2 8 12.24 12.24 0 0 1-3.2 8m0-16a12.24 12.24 0 0 0-3.2 8 12.24 12.24 0 0 0 3.2 8m0-16a8 8 0 1 0 0 16m0-16a8 8 0 1 1 0 16M3.4 11.6h15.2M3.4 16.4h15.2M18 5l2 2m0 0 2-2m-2 2V1"
    />
  </svg>
)
