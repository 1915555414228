/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBuildingArrowDown01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBuildingArrowDown01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.8 11.222h4.7c.717 0 1.243.31 1.526.85.174.332.174.768.174 1.64V19m-6.4 0V7.489c0-.871 0-1.307-.174-1.64a1.58 1.58 0 0 0-.7-.68C11.584 5 11.136 5 10.24 5H7.36c-.896 0-1.344 0-1.686.17-.301.149-.546.387-.7.68-.174.332-.174.768-.174 1.639V19M20 19H4M7.6 8.111H10m-2.4 3.111H10m-2.4 3.111H10M18 7l2 2m0 0 2-2m-2 2V3"
    />
  </svg>
)
